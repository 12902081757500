<template>
  <div class="checkout-options">
    <b-card>

      <h4 class="mb-1">Samenvatting</h4>
      <hr>
      <div class="price-details">
        <h6 class="price-title"
            v-if="selectedCompanyData"
        >
          Verzenden aan
        </h6>
        <ul
          v-if="selectedCompanyData"
          class="list-unstyled"
        >
          <li class="price-detail">
            <div class="detail-title">
              Opdrachtgever
            </div>
            <div class="detail-amt">
              {{ selectedCompanyData.name }}
            </div>
          </li>
          <li class="price-detail">
            <div class="detail-title">
              E-mailadres
            </div>
            <div class="detail-amt discount-amt">
              {{ selectedCompanyData.email }}
            </div>
          </li>
        </ul>
        <h6 class="price-title">
          Factuur details
        </h6>
        <ul class="list-unstyled">
          <li class="price-detail">
            <div class="detail-title">
              Totaal excl. btw
            </div>
            <div class="detail-amt">
              &euro; {{ parseFloat(totalAmountExBtwData).toFixed(2) }}
            </div>
          </li>
          <li class="price-detail">
            <div class="detail-title">
              Btw <span v-if="selectedBtwData&&selectedBtwData.label">({{selectedBtwData.label}})</span>
            </div>
            <div class="detail-amt discount-amt">
              &euro; {{ parseFloat(totalBtwData).toFixed(2) }}
            </div>
          </li>
          <li class="price-detail">
            <div class="detail-title">
              Totaal incl. btw
            </div>
            <div class="detail-amt discount-amt">
              &euro; {{ parseFloat(totalAmountIncBtwData).toFixed(2) }}
            </div>
          </li>
        </ul>
        <h6 class="price-title">
          Verloning {{selectedPayPeriodData&&selectedPayPeriodData.label?selectedPayPeriodData.label:''}}
        </h6>
        <ul class="list-unstyled">
          <li class="price-detail">
            <div class="detail-title">
              Te verlonen (Inhouding fee)
            </div>
            <div class="detail-amt">
              &euro; {{parseFloat(previewCalculationData.realGrossSalary).toFixed(2)}}
            </div>
          </li>
          <li class="price-detail">
            <div class="detail-title">
              Bruto loon
            </div>
            <div class="detail-amt">
              &euro; {{parseFloat(previewCalculationData.grossSalary).toFixed(2)}}
            </div>
          </li>
          <li class="price-detail">
            <div class="detail-title">
              Netto loon
            </div>
            <div class="detail-amt discount-amt">
              &euro; {{parseFloat(previewCalculationData.netPay).toFixed(2)}}
            </div>
          </li>
        </ul>
<!--        <hr>-->
<!--        <ul class="list-unstyled">-->
<!--          <li class="price-detail">-->
<!--            <div class="detail-title detail-total">-->
<!--              Totaal-->
<!--            </div>-->
<!--            <div class="detail-amt font-weight-bolder">-->
<!--              &euro; {{parseFloat(netPayData).toFixed(2)}}-->
<!--            </div>-->
<!--          </li>-->
<!--        </ul>-->
      </div>

    </b-card>
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,

  },
  props: {
    selectedCompanyData: {
      type: Object,
      default: () => {
      },
    },
    selectedBtwData: {
      type: Object,
      default: () => null,
    },
    selectedPayPeriodData: {
      type: Object,
      default: () => null,
    },
    totalAmountExBtwData: {
      type: Number,
      default: () => 0,
    },
    totalBtwData: {
      type: Number,
      default: () => 0,
    },
    totalAmountIncBtwData: {
      type: Number,
      default: () => 0,
    },
    previewCalculationData: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
