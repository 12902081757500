<template>
  <div class="list-view product-checkout mt-0">
    <b-modal
      id="modal-add-expenses"
      ref="modalAddExpenses"
      hide-footer
      centered
      size="sm"
      title="Onkosten toevoegen"
      :no-close-on-backdrop="false"
    >
      <b-card-text>
        <template #title>
          <feather-icon icon="TagIcon" />
          <span>Onkosten toevoegen</span>
        </template>

        <b-card-text>
          <validation-observer
            #default="{ handleSubmit }"
            ref="refmodalAddExpenses"
          >
            <b-form
              @submit.prevent="handleSubmit(addNewExpenses)"
            >
              <b-row>
                <b-col
                  cols="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Type"
                    rules="required"
                  >
                    <b-form-group
                      label="Type"
                      label-for="type"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="addExpsensesData.type"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="expenseType"
                        label="label"
                        :reduce="val => val.value"
                        :clearable="false"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="addExpsensesData.type === 1"
                  cols="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Locatie van"
                    rules="required"
                  >
                    <b-form-group
                      label="Locatie van"
                      label-for="locationFrom"
                    >
                      <b-form-input
                        id="locationFrom"
                        v-model="addExpsensesData.travelFrom"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="addExpsensesData.type === 1"
                  cols="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Locatie naar"
                    rules="required"
                  >
                    <b-form-group
                      label="Locatie naar"
                      label-for="locationTo"
                    >
                      <b-form-input
                        id="locationTo"
                        v-model="addExpsensesData.travelTo"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="addExpsensesData.type === 1"
                  cols="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Aantal kilometers"
                    rules="required"
                  >
                    <b-form-group
                      label="Aantal kilometers"
                      label-for="kilometers"
                    >
                      <b-form-input
                        id="kilometers"
                        v-model="addExpsensesData.travelDistance"
                        type="number"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="addExpsensesData.type === 2"
                  cols="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Reden uitgave"
                    rules="required"
                  >
                    <b-form-group
                      label="Reden uitgave"
                      label-for="reasonID"
                      :state="getValidationState(validationContext)"
                    >
                      <v-select
                        v-model="addExpsensesData.reasonID"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="expenseReasons"
                        label="label"
                        :reduce="val => val.value"
                        :clearable="false"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="addExpsensesData.type === 2"
                  cols="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Omschrijving"
                    rules="required"
                  >
                    <b-form-group
                      label="Omschrijving"
                      label-for="kilometers"
                    >
                      <b-form-input
                        id="description"
                        v-model="addExpsensesData.description"
                        type="text"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="addExpsensesData.type === 2"
                  cols="8"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Kopie bon"
                    rules="required"
                  >
                    <b-form-group
                      label="Kopie bon"
                      label-for="fileName"
                    >
                      <b-form-input
                        id="fileName"
                        v-model="addExpsensesData.fileName"
                        :state="getValidationState(validationContext)"
                        readonly="readonly"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  v-if="addExpsensesData.type === 2"
                  cols="4"
                  class="d-flex align-items-center"
                >
                  <b-button
                    v-b-toggle.report-list
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    style="margin-top:10px"
                    size="sm"
                    variant="outline-primary"
                    @click="$refs.refInputExpenses.click()"
                  >
                    <b-spinner
                      v-if="showUploadLoader"
                      small
                      class="mr-1"
                    />
                    <input
                      id="refInputExpenses"
                      ref="refInputExpenses"
                      type="file"
                      class="d-none refInputExpenses"
                      @input="uploadImage"
                    >
                    <span class="d-none d-sm-inline">Uploaden</span>
                    <feather-icon
                      icon="EditIcon"
                      class="d-inline d-sm-none"
                    />
                  </b-button>
                </b-col>
                <b-col
                  v-if="addExpsensesData.type === 2"
                  cols="12"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Bedrag"
                    rules="required"
                  >
                    <b-form-group
                      label="Bedrag"
                      label-for="amount"
                    >
                      <b-form-input
                        id="amount"
                        v-model="addExpsensesData.amount"
                        :state="getValidationState(validationContext)"
                        type="number"
                        step="any"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                >
                  <b-form-checkbox
                    v-model="addExpsensesData.isInvoiced"
                    :value="1"
                    :unchecked-value="0"
                  >Factureren
                  </b-form-checkbox>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12 text-right mt-1 mb-2">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    type="submit"
                    :disabled="disableExpensesAddButton"
                  >
                    <span>Toevoegen</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-text>
      </b-card-text>
    </b-modal>
    <b-modal
      id="modal-add-company"
      ref="modalAddCompany"
      hide-footer
      centered
      size="lg"
      title="Bedrijf toevoegen"
      :no-close-on-backdrop="false"
    >
      <b-card-text>
        <template #title>
          <feather-icon icon="UserPlusIcon" />
          <span>Bedrijf aanmaken</span>
        </template>

        <b-card-text>
          <validation-observer
            #default="{ handleSubmit }"
            ref="refFormSearchRelationObserver"
          >
            <b-form
              @submit.prevent="handleSubmit(createCompany)"
            >
              <h6 class="card-title mt-1">
                Bedrijfsinformatie
              </h6>
              <b-row>
                <b-col
                  cols="12"
                  md="12"
                >
                  <b-form-group
                    label="Bedrijf"
                    label-for="company"
                  >
                    <vue-autosuggest
                      ref="autocomplete"
                      v-model="query"
                      :suggestions="suggestions"
                      :input-props="inputProps"
                      :get-suggestion-value="getSuggestionValue"
                      @selected="onSelected($event.item)"
                      @input="fetchResults"
                    >
                      <template slot-scope="{suggestion}">
                        <b-row>
                          <b-col
                            cols="12"
                          >
                            <span class="my-suggestion-item">{{
                              `${suggestion.item.companyName} - ${suggestion.item.cocNumber}`
                            }}</span>
                          </b-col>
                          <b-col
                            cols="12"
                          >
                            <span class="my-suggestion-item">{{
                              `${suggestion.item.street} ${suggestion.item.houseNumber}${suggestion.item.houseNumberAddition}, ${suggestion.item.postalCode} ${suggestion.item.city}`
                            }}</span>
                          </b-col>
                        </b-row>
                      </template>
                    </vue-autosuggest>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Bedrijfsnaam"
                    rules="required"
                  >
                    <b-form-group
                      label="Bedrijfsnaam"
                      label-for="companyName"
                    >
                      <b-form-input
                        id="companyName"
                        v-model="companyData.companyName"
                        disabled="disabled"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="KvK-nr"
                    rules="required"
                  >
                    <b-form-group
                      label="KvK-nr"
                      label-for="cocNumber"
                    >
                      <b-form-input
                        id="cocNumber"
                        v-model="companyData.cocNumber"
                        disabled="disabled"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Straat"
                    rules="required"
                  >
                    <b-form-group
                      label="Straat"
                      label-for="street"
                    >
                      <b-form-input
                        id="street"
                        v-model="companyData.street"
                        disabled="disabled"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Huisnummer"
                    rules="required"
                  >
                    <b-form-group
                      label="Huisnummer"
                      label-for="houseNumber"
                    >
                      <b-form-input
                        id="houseNumber"
                        v-model="companyData.houseNumber"
                        disabled="disabled"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Postcode"
                    rules="required"
                  >
                    <b-form-group
                      label="Postcode"
                      label-for="postalCode"
                    >
                      <b-form-input
                        id="postalCode"
                        v-model="companyData.postalCode"
                        disabled="disabled"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Plaats"
                    rules="required"
                  >
                    <b-form-group
                      label="Plaats"
                      label-for="city"
                    >
                      <b-form-input
                        id="city"
                        v-model="companyData.city"
                        disabled="disabled"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <h6 class="card-title mt-1">
                Contactpersoon
              </h6>
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Voornaam"
                    rules="required"
                  >
                    <b-form-group
                      label="Voornaam"
                      label-for="firstName"
                    >
                      <b-form-input
                        id="firstName"
                        v-model="companyData.firstName"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Achternaam"
                    rules="required"
                  >
                    <b-form-group
                      label="Achternaam"
                      label-for="lastName"
                    >
                      <b-form-input
                        id="lastName"
                        v-model="companyData.lastName"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="E-mailadres"
                    rules="required"
                  >
                    <b-form-group
                      label="E-mailadres"
                      label-for="email"
                    >
                      <b-form-input
                        id="email"
                        v-model="companyData.email"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Telefoon"
                    rules="required|integer"
                  >
                    <b-form-group
                      label="Telefoon"
                      label-for="phone"
                    >
                      <b-form-input
                        id="phone"
                        v-model="companyData.phone"
                        :state="getValidationState(validationContext)"
                        type="text"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <h6 class="card-title mt-1">
                Overige informatie
              </h6>
              <b-row>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="BTW-Nummer"
                    rules=""
                  >
                    <b-form-group
                      label="BTW-Nummer"
                      label-for="vatId"
                    >
                      <b-form-input
                        id="vatNumber"
                        v-model="companyData.vatNumber"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="Betaaltermijn"
                    rules="required"
                  >
                    <b-form-group
                      label="Betaaltermijn"
                      label-for="paymentTermDays"
                    >
                      <b-form-input
                        id="paymentTermDays"
                        v-model="companyData.paymentTermDays"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12 text-right mt-1 mb-2">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    type="submit"
                    :disabled="showAddCompanySpinner"
                  >
                    <b-spinner
                      v-if="showAddCompanySpinner"
                      small
                      class="mr-1"
                    />
                    <span>Toevoegen</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-text>
      </b-card-text>
    </b-modal>
    <b-modal
      id="modal-add-freelancer"
      ref="modalAddfreelancer"
      hide-footer
      centered
      size="lg"
      title="Freelancer toevoegen"
      :no-close-on-backdrop="false"
    >
      <b-card-text>
        <template #title>
          <feather-icon icon="UserPlusIcon"/>
          <span>Freelancer Toevoegen</span>
        </template>

        <b-card-text>
          <validation-observer
            #default="{ handleSubmit }"
          >
            <b-form
              @submit.prevent="handleSubmit(createFreelancer)"
            >
              <b-row>
                <b-col
                  cols="12"
                  md="4"
                >
                  <validation-provider
                    #default="validationContext"
                    name="firstNameFreelancer"
                    rules="required"
                  >
                    <b-form-group
                      label="Voornaam"
                      label-for="firstNameFreelancer"
                    >
                      <b-form-input
                        id="firstNameFreelancer"
                        v-model="freelancerData.firstName"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="3"
                >
                  <validation-provider
                    #default="validationContext"
                    name="middleNameFreelancer"
                    rules=""
                  >
                    <b-form-group
                      label="Tussenvoegsel"
                      label-for="middleNameFreelancer"
                    >
                      <b-form-input
                        id="middleNameFreelancer"
                        v-model="freelancerData.middleName"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="5"
                >
                  <validation-provider
                    #default="validationContext"
                    name="lastNameFreelancer"
                    rules="required"
                  >
                    <b-form-group
                      label="Achternaam"
                      label-for="lastNameFreelancer"
                    >
                      <b-form-input
                        id="lastNameFreelancer"
                        v-model="freelancerData.lastName"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="emailfreelancer"
                    rules="required|email"
                  >
                    <b-form-group
                      label="E-mailadres"
                      label-for="emailfreelancer"
                    >
                      <b-form-input
                        id="emailfreelancer"
                        v-model="freelancerData.email"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="phonefreelancer"
                    rules="required|integer"
                  >
                    <b-form-group
                      label="Telefoon"
                      label-for="phonefreelancer"
                    >
                      <b-form-input
                        id="phonefreelancer"
                        v-model="freelancerData.phone"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                  md="6"
                >
                  <validation-provider
                    #default="validationContext"
                    name="externalIdentifierFreelancer"
                    rules=""
                  >
                    <b-form-group
                      label="Kenmerk"
                      label-for="externalIdentifierFreelancer"
                    >
                      <b-form-input
                        id="externalIdentifierFreelancer"
                        v-model="freelancerData.externalIdentifier"
                        :state="getValidationState(validationContext)"
                      />
                      <b-form-invalid-feedback :state="getValidationState(validationContext)">
                        {{ validationContext.errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col
                  cols="12"
                >
                  <b-form-group>
                    <b-form-checkbox
                      name="companyPaysFeeFreelancer"
                      v-model="freelancerData.companyPaysFee"
                    >
                      Bedrijf betaald de handeling fee
                    </b-form-checkbox>
                  </b-form-group>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12 text-right mt-1 mb-2">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    type="submit"
                    :disabled="showAddFreelancerSpinner"
                  >
                    <b-spinner
                      small
                      class="mr-1"
                      v-if="showAddFreelancerSpinner"
                    />
                    <span>Toevoegen</span>
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-card-text>
      </b-card-text>
    </b-modal>
    <template>
      <section class="invoice-add-wrapper">
        <b-row class="invoice-add">
          <b-col
            cols="12"
            md="12"
          >
            <validation-observer
              #default="{ handleSubmit }"
              ref="refStepCreateInvoiceItemsObserver"
            >
              <b-form
                @submit.prevent="handleSubmit(onSubmit)"
              >
                <b-card
                  no-body
                  class="invoice-preview-card"
                >
                  <!-- Header -->
                  <b-card-body class="invoice-padding pb-0 mt-2">
                    <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
                      <!-- Header: Left Content -->
                      <div v-if="userData.type === 1">
                        <b-card-text class="mb-25">
                          <strong>{{ getFullName(profileData) }}</strong>
                        </b-card-text>
                        <b-card-text class="mb-25">
                          {{ profileData.street }} {{ profileData.houseNumber }}{{
                            profileData.houseNumberAddition
                          }}<br>
                          {{ profileData.postalCode }} {{ profileData.city }}
                        </b-card-text>
                        <b-card-text class="mb-0">
                          {{ profileData.phone }}<br>
                          {{ profileData.email }}
                        </b-card-text>
                      </div>
                      <div>
                        <validation-provider
                          v-if="userData.type === 2"
                          #default="validationContext"
                          name="idType"
                          rules="required"
                        >
                          <b-form-group
                            label="Kies hier de freelancer wie je een factuurvoorstel wilt versturen"
                            label-for="idType"
                            :state="getValidationState(validationContext)"
                          >
                            <v-select
                              v-model="invoiceData.freelancer"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="freelancersData"
                              label="fullName"
                              :clearable="false"
                              @input="setSelectedFreelancer"
                            >
                              <template #list-header>
                                <li
                                  v-b-toggle.sidebar-invoice-add-new-customer
                                  class="add-new-freelancer-header d-flex align-items-center my-50"
                                  @click="openModalCreateFreelancer"
                                >
                                  <feather-icon
                                    icon="PlusIcon"
                                    size="16"
                                  />
                                  <span class="align-middle ml-25">Nieuwe freelancer aanmaken</span>
                                </li>
                              </template>
                            </v-select>
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                        <template v-if="userData.type === 2 && selectedFreelancerData != null">
                          <b-card-text
                            class="mb-25"
                            v-if="selectedFreelancerData.street && selectedFreelancerData.houseNumber && selectedFreelancerData.postalCode && selectedFreelancerData.city"
                          >
                            {{ selectedFreelancerData.street }} {{ selectedFreelancerData.houseNumber }}{{selectedFreelancerData.houseNumberAddition?selectedFreelancerData.houseNumberAddition:''}}<br>
                            {{ selectedFreelancerData.postalCode }} {{ selectedFreelancerData.city }}
                          </b-card-text>
                          <b-card-text class="mb-0">
                            {{ selectedFreelancerData.phone }}<br>
                            {{ selectedFreelancerData.email }}
                          </b-card-text>
                        </template>
                      </div>
                      <!-- Header: Right Content -->
                      <div class="invoice-number-date mt-md-0 mt-2">
                        <div class="d-flex align-items-center mb-1">
                          <span class="title">
                            Datum: {{ invoiceData.invoiceDate }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </b-card-body>

                  <!-- Spacer -->
                  <hr class="invoice-spacing">

                  <!-- Invoice Client & Payment Details -->
                  <b-card-body
                    class="invoice-padding pt-0"
                  >
                    <b-row class="invoice-spacing">

                      <!-- Col: Invoice To -->
                      <b-col
                        cols="12"
                        xl="8"
                        class="mb-lg-1"
                      >
                        <h6 class="mb-2">
                          Opdrachtgever:
                        </h6>

                        <validation-provider
                          v-if="userData.type === 1"
                          #default="validationContext"
                          name="organisation"
                          rules="required"
                        >
                          <b-form-group
                            label="Kies hier naar welke opdrachtgever je jouw factuur wilt verzenden"
                            label-for="organisation"
                            :state="getValidationState(validationContext)"
                          >
                            <v-select
                              v-model="invoiceData.organisation"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="companiesData"
                              label="name"
                              :clearable="false"
                              @input="setSelectedCompany"
                              id="organisation"
                            >
                              <template #list-header>
                                <li
                                  v-b-toggle.sidebar-invoice-add-new-customer
                                  class="add-new-organisation-header d-flex align-items-center my-50"
                                  @click="openModalCreateCompany"
                                >
                                  <feather-icon
                                    icon="PlusIcon"
                                    size="16"
                                  />
                                  <span class="align-middle ml-25">Nieuwe opdrachtgever aanmaken</span>
                                </li>
                              </template>
                            </v-select>
                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                        <!-- Selected Client -->
                        <div
                          v-if="selectedCompanyData && userData.type === 1"
                          class="mt-1"
                        >
                          <b-card-text class="mb-25">
                            {{ selectedCompanyData.street }} {{
                              selectedCompanyData.houseNumber
                            }}{{ selectedCompanyData.houseNumberAddition }}<br>
                            {{ selectedCompanyData.postalCode }} {{ selectedCompanyData.city }}
                          </b-card-text>
                          <b-card-text class="mb-25">
                            {{ selectedCompanyData.email }}
                          </b-card-text>
                          <b-card-text class="mb-0">
                            {{ selectedCompanyData.iban }}
                          </b-card-text>
                        </div>
                        <div
                          v-if="userData.type === 2"
                          class="mt-1"
                        >
                          <b-card-text class="mb-25" v-if="profileData && profileData.company">
                            {{ profileData.company.name }}<br/>
                            {{ getFullName(profileData.company) }}
                          </b-card-text>
                          <b-card-text
                            class="mb-25"
                            v-if="profileData && profileData.company && profileData.company.street && profileData.company.houseNumber && profileData.company.postalCode && profileData.company.city"
                          >
                            {{ profileData.company.street }} {{profileData.company.houseNumber}}{{ profileData.company.houseNumberAddition?profileData.company.houseNumberAddition:''}}<br>
                            {{ profileData.company.postalCode }} {{ profileData.company.city }}
                          </b-card-text>
                          <b-card-text
                            v-if="profileData && profileData.company && profileData.company.email"
                            class="mb-25"
                          >
                            {{ profileData.company.email }}
                          </b-card-text>
                        </div>
                      </b-col>

                      <!-- Col: Payment Details -->
                      <b-col
                        cols="12"
                        xl="4"
                        class="mb-lg-1"
                      >
                        <b-row>
                          <b-col
                            cols="12"
                          >
                            <h6 class="mb-2">
                              Algemene informatie:
                            </h6>
                          </b-col>
                          <b-col
                            cols="12"
                          >
                            <validation-provider
                              #default="validationContext"
                              name="Btw"
                              rules="required"
                            >
                              <b-form-group
                                label="Btw"
                                label-for="btw"
                                :state="getValidationState(validationContext)"
                              >
                                <v-select
                                  id="btw"
                                  v-model="invoiceData.btw"
                                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                  :options="btwOptions"
                                  :reduce="val => val.value"
                                  :clearable="false"
                                  @input="setSelectedBtw"
                                />
                                <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                  {{ validationContext.errors[0] }}
                                </b-form-invalid-feedback>
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                          <b-col
                            v-if="invoiceData.btw !== 0"
                            cols="12"
                          >
                            <validation-provider
                              #default="validationContext"
                              name="vatReason"
                              rules="required"
                            >
                              <b-form-group
                                label="Reden Btw keuze"
                                label-for="vatReason"
                              >
                                <b-form-input
                                  id="vatReason"
                                  v-model="invoiceData.vatReason"
                                  type="text"
                                  :state="getValidationState(validationContext)"
                                />
                              </b-form-group>
                            </validation-provider>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-card-body>

                  <!-- Items Section -->
                  <b-card-body class="invoice-padding form-item-section">
                    <div
                      ref="formInvoiceLines"
                      class="repeater-form"
                      :style="{height: trHeightInvoiceLines}"
                    >
                      <b-row>
                        <!-- Item Form -->
                        <!-- ? This will be in loop => So consider below markup for single item -->
                        <b-col cols="12">
                          <h4 class="mb-2">
                            Factuurregels
                          </h4>

                          <!-- ? Flex to keep separate width for XIcon and SettingsIcon -->
                          <div
                            v-if="invoiceData.invoiceLines.length > 0"
                            class="d-none d-lg-flex mb-2"
                          >
                            <b-row class="flex-grow-1 px-1">
                              <!-- Single Item Form Headers -->
                              <b-col
                                cols="12"
                                md="4"
                              >
                                Omschrijving
                              </b-col>
                              <b-col
                                cols="12"
                                md="3"
                              >
                                Aantal
                              </b-col>
                              <b-col
                                cols="12"
                                md="3"
                              >
                                Bedrag
                              </b-col>
                              <b-col
                                cols="12"
                                md="2"
                              >
                                Totaal
                              </b-col>
                            </b-row>
                            <div class="form-item-action-col" />
                          </div>
                          <div
                            v-for="(item, index) in invoiceData.invoiceLines"
                            :key="`invoice-line-${index}`"
                            ref="rowInvoiceLines"
                            class="d-flex border rounded"
                          >
                            <b-row
                              class="flex-grow-1 p-2"
                            >
                              <!-- Single Item Form Headers -->
                              <b-col
                                cols="12"
                                md="4"
                              >
                                <label class="d-inline d-md-none">Omschrijving</label>
                                <validation-provider
                                  #default="validationContext"
                                  :name="`itemDescription${index}`"
                                  rules="required"
                                >
                                  <b-form-group
                                    label=""
                                    :label-for="`itemDescription${index}`"
                                  >
                                    <b-form-input
                                      :id="`itemDescription${index}`"
                                      v-model="item.description"
                                      type="text"
                                      :state="getValidationState(validationContext)"
                                    />
                                  </b-form-group>
                                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                    {{ validationContext.errors[0] }}
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-col>
                              <b-col
                                cols="12"
                                md="3"
                              >
                                <label class="d-inline d-md-none">Aantal</label>
                                <validation-provider
                                  #default="validationContext"
                                  :name="`itemQuantity${index}`"
                                  rules="required"
                                >
                                  <b-form-group
                                    label=""
                                    :label-for="`itemQuantity${index}`"
                                  >
                                    <b-form-input
                                      :id="`itemQuantity${index}`"
                                      v-model="item.quantity"
                                      type="number"
                                      step="0.01"
                                      :state="getValidationState(validationContext)"
                                    />
                                  </b-form-group>
                                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                    {{ validationContext.errors[0] }}
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-col>
                              <b-col
                                cols="12"
                                md="3"
                              >
                                <label class="d-inline d-md-none">Bedrag</label>
                                <validation-provider
                                  #default="validationContext"
                                  :name="`itemAmount${index}`"
                                  :rules="`required|minAmount:${profileData && profileData.minimumTariff?profileData.minimumTariff:0}`"
                                >
                                  <b-form-group
                                    label=""
                                    :label-for="`itemAmount${index}`"
                                  >
                                    <b-form-input
                                      :id="`itemAmount${index}`"
                                      v-model="item.amount"
                                      type="number"
                                      :state="getValidationState(validationContext)"
                                      step="any"
                                    />
                                  </b-form-group>
                                  <b-form-invalid-feedback :state="getValidationState(validationContext)">
                                    {{ validationContext.errors[0] }}
                                  </b-form-invalid-feedback>
                                </validation-provider>
                              </b-col>
                              <b-col
                                cols="12"
                                md="2"
                              >
                                <label class="d-inline d-md-none">Totaal</label>
                                <p style="margin-top: 10px">
                                  &euro;
                                  {{ item.amount && item.quantity ? (item.amount * item.quantity).toFixed(2) : '0.00' }}
                                </p>
                              </b-col>
                            </b-row>
                            <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                              <feather-icon
                                v-visible="0 !== index || invoiceData.invoiceLines.length > 1"
                                size="16"
                                icon="XIcon"
                                class="cursor-pointer"
                                @click="removeInvoiceLine(index)"
                              />
                              <feather-icon
                                :id="`form-item-settings-icon-${index}`"
                                size="16"
                                icon="CopyIcon"
                                class="cursor-pointer"
                                @click="copyInvoiceLine(item)"
                              />
                              <feather-icon
                                :id="`form-item-add-icon-${index}`"
                                v-visible="invoiceData.invoiceLines.length - 1 === index"
                                size="16"
                                icon="PlusIcon"
                                class="cursor-pointer"
                                @click="addNewInvoiceLine()"
                              />
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                  <b-card-body class="invoice-padding form-item-section">
                    <div
                      ref="formExpenses"
                      class="repeater-form"
                      :style="{height: trHeightExpenses}"
                    >
                      <b-row>
                        <!-- Item Form -->
                        <!-- ? This will be in loop => So consider below markup for single item -->
                        <b-col cols="12">
                          <div
                            v-if="invoiceData.expenses.length > 0"
                            class="d-none d-lg-flex mt-2 mb-2"
                          >
                            <b-row class="flex-grow-1 px-1">
                              <!-- Single Item Form Headers -->
                              <b-col
                                cols="12"
                                md="6"
                              >
                                Onkosten omschrijving
                              </b-col>
                              <b-col
                                cols="12"
                                lg="2"
                              >
                                Factureren
                              </b-col>
                              <b-col
                                cols="12"
                                lg="2"
                              >
                                Bedrag
                              </b-col>
                              <b-col
                                cols="12"
                                lg="2"
                              >
                                Totaal
                              </b-col>
                            </b-row>
                            <div class="form-item-action-col" />
                          </div>
                          <div
                            v-for="(item, index) in invoiceData.expenses"
                            :key="`expenses-${index}`"
                            ref="rowExpenses"
                            class="d-flex border rounded"
                          >
                            <b-row
                              class="flex-grow-1 p-2"
                            >
                              <!-- Single Item Form Headers -->
                              <b-col
                                cols="12"
                                lg="6"
                              >
                                <label class="d-inline d-md-none">Onkosten omschrijving</label>
                                <b-form-input
                                  v-model="item.description"
                                  type="text"
                                  readonly="readonly"
                                />
                              </b-col>
                              <b-col
                                cols="12"
                                lg="2"
                              >
                                <label class="d-inline d-md-none">Factureren</label>
                                <b-form-checkbox
                                  v-model="item.isInvoiced"
                                  class="mt-1"
                                  :value="1"
                                  :unchecked-value="0"
                                />
                              </b-col>
                              <b-col
                                cols="12"
                                lg="2"
                              >
                                <label class="d-inline d-md-none">Bedrag</label>
                                <input
                                  v-model="item.amount"
                                  type="hidden"
                                >
                                <p style="margin-top: 10px">
                                  &euro; {{ item.amount ? parseFloat(item.amount).toFixed(2) : '0.00' }}
                                </p>
                              </b-col>
                              <b-col
                                cols="12"
                                lg="2"
                              >
                                <label class="d-inline d-md-none">Totaal</label>
                                <p style="margin-top: 10px">
                                  &euro; {{ item.isInvoiced ? parseFloat(item.amount).toFixed(2) : '0.00' }}
                                </p>
                              </b-col>
                            </b-row>
                            <div class="d-flex flex-column justify-content-between border-left py-50 px-25">
                              <feather-icon
                                size="16"
                                icon="XIcon"
                                class="cursor-pointer"
                                @click="removeExpenses(index)"
                              />
                              <feather-icon
                                :id="`form-item-settings-icon-${index}`"
                                size="16"
                                icon="CopyIcon"
                                class="cursor-pointer"
                                @click="copyExpenses(item)"
                              />
                            </div>
                          </div>
                        </b-col>
                      </b-row>
                    </div>
                  </b-card-body>
                  <b-card-body class="">
                    <b-row>
                      <b-col
                        cols="12"
                      >
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          class="ml-1"
                          variant="secondary"
                          @click="$refs.modalAddExpenses.show()"
                        >
                          Onkosten toevoegen
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-card-body>
                  <!-- Spacer -->
                  <hr class="invoice-spacing">

                  <!-- Note -->
                  <b-card-body class="invoice-padding pt-0">
                    <b-row>
                      <b-col
                        cols="6 text-left"
                      />
                      <b-col
                        cols="6 text-right"
                      >
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          type="submit"
                        >
                          <span>Volgende</span>
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-card-body>
                </b-card>
              </b-form>
            </validation-observer>
          </b-col>
        </b-row>
      </section>
    </template>

    <!-- Checkout Options -->
    <invoice-summary
      :selected-company-data="selectedCompanyData"
      :selected-btw-data="selectedBtwData"
      :selected-pay-period-data="selectedPayPeriodData"
      :total-amount-ex-btw-data="totalAmountExBtwData"
      :total-btw-data="totalBtwData"
      :total-amount-inc-btw-data="totalAmountIncBtwData"
      :preview-calculation-data="previewCalculationData"
    />
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCol,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BModal,
  BRow,
  BSpinner,
  VBToggle,
} from 'bootstrap-vue'
import { formatDate, getFullName, getPayPeriods } from '@core/utils/utils'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { heightTransition } from '@core/mixins/ui/transition'
import store from '@/store'
import invoicesStoreModule from '@/views/pages/invoices/invoicesStoreModule'
import { onUnmounted, ref } from '@vue/composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { VueAutosuggest } from 'vue-autosuggest'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required, minAmount, integer } from '@core/utils/validations/validations'
import InvoiceSummary from '@/views/pages/invoices/component/InvoiceSummary.vue'

export default {
  components: {
    InvoiceSummary,
    // BSV
    BButton,
    BCard,
    BFormInput,
    BRow,
    BCol,
    BCardText,
    BForm,
    BFormGroup,
    BModal,
    BFormCheckbox,
    BSpinner,
    BCardBody,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    VueAutosuggest,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  mixins: [heightTransition],
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
    profileData: {
      type: Object,
      default: () => {
      },
    },
    selectedCompanyData: {
      type: Object,
      default: () => {
      },
    },
    companiesData: {
      type: Array,
      default: () => {
      },
    },
    freelancersData: {
      type: Array,
      default: () => {
      },
    },
    selectedFreelancerData: {
      type: Object,
      default: () => {
      },
    },
    selectedBtwData: {
      type: Object,
      default: () => null,
    },
    selectedPayPeriodData: {
      type: Object,
      default: () => null,
    },
    totalAmountExBtwData: {
      type: Number,
      default: () => 0,
    },
    totalBtwData: {
      type: Number,
      default: () => 0,
    },
    totalAmountIncBtwData: {
      type: Number,
      default: () => 0,
    },
    previewCalculationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      companyData: {},
      freelancerData: {},
      inputProps: {
        id: 'autosuggest__input_ajax',
        class: 'form-control',
        placeholder: 'Zoek bedrijf in KvK register',
      },
      limit: 10,
      query: '',
      results: [],
      timeout: null,
      debounceMilliseconds: 250,
      suggestions: [],
      showAddCompanySpinner: false,
      showAddFreelancerSpinner: false,
      trHeightInvoiceLines: 0,
      trHeightExpenses: 0,
      addExpsensesData: {
        type: 1,
        travelFrom: null,
        travelTo: null,
        travelDistance: null,
        reasonID: null,
        description: '',
        fileID: '',
        fileName: '',
        amount: null,
      },
      showUploadLoader: false,
      disableExpensesAddButton: false,
      totalInvocieLines: 0,
      totalExpenseAmount: 0,
    }
  },
  watch: {
    invoiceData: {
      deep: true,
      async handler(data) {
        await this.$emit('set-total-amount-ex-btw', 0)
        this.totalInvocieLines = 0
        this.totalExpenseAmount = 0

        // eslint-disable-next-line no-restricted-syntax
        for (const element of data.invoiceLines) {
          if (element.amount && element.quantity) {
            // eslint-disable-next-line no-await-in-loop
            await this.$emit('set-total-amount-ex-btw', ((+element.amount * +element.quantity) + +this.totalAmountExBtwData).toFixed(2))
            this.totalInvocieLines = ((+element.amount * +element.quantity) + +this.totalInvocieLines).toFixed(2)
          }
        }

        if (data.invoiceLines.length > 0) {
          // eslint-disable-next-line no-restricted-syntax
          for (const element of data.expenses) {
            if (element.amount && element.isInvoiced) {
              // eslint-disable-next-line no-await-in-loop
              await this.$emit('set-total-amount-ex-btw', (+element.amount + +this.totalAmountExBtwData).toFixed(2))
              // eslint-disable-next-line radix
              this.totalExpenseAmount = (+element.amount + +this.totalExpenseAmount).toFixed(2)
            }
          }
        }

        await this.$emit('set-total-amount-inc-btw', 0)
        if (this.selectedBtwData && this.selectedBtwData.value === 0 && +this.totalAmountExBtwData > 0) {
          await this.$emit('set-total-amount-inc-btw', (+this.totalAmountExBtwData * 1.21).toFixed(2))
          await this.$emit('set-total-btw', (+this.totalAmountExBtwData * 0.21).toFixed(2))
        } else if (this.selectedBtwData && this.selectedBtwData.value === 1 && +this.totalAmountExBtwData > 0) {
          await this.$emit('set-total-amount-inc-btw', (+this.totalAmountExBtwData * 1.09).toFixed(2))
          await this.$emit('set-total-btw', (+this.totalAmountExBtwData * 0.09).toFixed(2))
        } else {
          await this.$emit('set-total-amount-inc-btw', (+this.totalAmountExBtwData).toFixed(2))
          await this.$emit('set-total-btw', 0)
        }

        if (this.selectedCompanyData && this.selectedCompanyData.id && (this.totalInvocieLines > 0 || this.totalExpenseAmount > 0)) {
          await this.previewCalculation()
        }
      },
    },
    selectedBtwData: {
      deep: true,
      async handler(data) {
        if (data.value === 0 && +this.totalAmountExBtwData > 0) {
          await this.$emit('set-total-amount-inc-btw', (+this.totalAmountExBtwData * 1.21).toFixed(2))
          await this.$emit('set-total-btw', (+this.totalAmountExBtwData * 0.21).toFixed(2))
        } else if (data.value === 1 && +this.totalAmountExBtwData > 0) {
          await this.$emit('set-total-amount-inc-btw', (+this.totalAmountExBtwData * 1.09).toFixed(2))
          await this.$emit('set-total-btw', (+this.totalAmountExBtwData * 0.09).toFixed(2))
        } else {
          await this.$emit('set-total-amount-inc-btw', (+this.totalAmountExBtwData).toFixed(2))
          await this.$emit('set-total-btw', 0)
        }
      },
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    previewCalculation() {
      store
        .dispatch('app-invoice-create/previewCalculation', {
          totalAmount: this.totalInvocieLines,
          totalExpenseAmount: this.totalExpenseAmount,
          freelancerID: JSON.parse(localStorage.getItem('userData')).id,
          companyPaysFee: 0,
          companyID: this.selectedCompanyData.id,
        })
        .then(async response => {
          await this.$emit('set-preview-calculation', { grossSalary: response.data.grossSalary, netPay: response.data.netPay, realGrossSalary: response.data.realGrossSalary })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Voorbeeldberekening',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het opslaan van de data. Probeer later het nog een keer.',
            },
          })
        })
    },
    fetchResults() {
      const { query } = this

      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        store
          .dispatch('app-invoice-create/searchCocRegister', {
            q: query,
            sortBy: 'name',
          })
          .then(response => {
            this.suggestions = [{
              data: response.data,
            }]
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Kvk register',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'Er is een fout opgetreden bij het opslaan van de data. Probeer later het nog een keer.',
              },
            })
          })
      }, this.debounceMilliseconds)
    },
    onSelected(value) {
      this.companyData = value
      this.companyData.name = value.companyName
    },
    getSuggestionValue(suggestion) {
      const { item } = suggestion
      return item.companyName
    },
    createCompany() {
      this.showAddCompanySpinner = true
      store.dispatch('app-invoice-create/addCompany', this.companyData)
        .then(() => {
          this.showAddCompanySpinner = false
          this.$refs.modalAddCompany.hide()
          this.$emit('fetch-companies-or-feelancers')
        })
        .catch(() => {
          this.showAddCompanySpinner = false
          this.$refs.modalAddCompany.hide()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Aanmaken bedrijf',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het opslaan van de data. Probeer later het nog een keer.',
            },
          })
        })
    },
    createfreelancer() {
      this.showAddFreelancerSpinner = true
      store.dispatch('app-invoice-create/addFreelancer', this.freelancerData)
        .then(() => {
          this.showAddFreelancerSpinner = false
          this.$refs.modalAddfreelancer.hide()
          this.$emit('fetch-companies-or-feelancers')
        })
        .catch(() => {
          this.showAddFreelancerSpinner = false
          this.$refs.modalAddfreelancer.hide()
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Aanmaken freelancer',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het opslaan van de data. Probeer later het nog een keer.',
            },
          })
        })
    },
    openModalCreateCompany() {
      this.$refs.modalAddCompany.show()
    },
    openModalCreateFreelancer() {
      this.$refs.modalAddfreelancer.show()
    },
    addNewInvoiceLine() {
      this.$refs.formInvoiceLines.style.overflow = 'hidden'
      this.invoiceData.invoiceLines.push(JSON.parse(JSON.stringify(this.blankInvoiceLine)))

      this.$nextTick(() => {
        this.trHeightInvoiceLines = this.getHeight(this.$refs.rowInvoiceLines[0].offsetHeight)
        setTimeout(() => {
          this.$refs.formInvoiceLines.style.overflow = null
        }, 350)
      })
    },
    addNewExpenses() {
      const expense = {}

      if (this.addExpsensesData.type === 1) {
        expense.type = 1
        expense.description = `Reiskosten ${this.addExpsensesData.travelFrom}-${this.addExpsensesData.travelTo} (${this.addExpsensesData.travelDistance}km)`
        expense.amount = this.addExpsensesData.travelDistance * 0.19
        expense.isInvoiced = this.addExpsensesData.isInvoiced
        expense.travelFrom = this.addExpsensesData.travelFrom
        expense.travelTo = this.addExpsensesData.travelTo
        expense.travelDistance = this.addExpsensesData.travelDistance
      }

      if (this.addExpsensesData.type === 2) {
        expense.type = 1
        expense.reasonID = this.addExpsensesData.reasonID.value
        expense.description = `Bon - ${this.addExpsensesData.description}`
        expense.fileID = this.addExpsensesData.fileID
        expense.amount = this.addExpsensesData.amount
      }

      this.$refs.formExpenses.style.overflow = 'hidden'
      this.invoiceData.expenses.push(JSON.parse(JSON.stringify(expense)))

      this.$nextTick(() => {
        if (this.$refs.rowExpenses && this.$refs.rowExpenses[0]) {
          this.trHeightExpenses = this.getHeight(this.$refs.rowExpenses[0].offsetHeight)
          setTimeout(() => {
            this.$refs.formExpenses.style.overflow = null
            this.$refs.modalAddExpenses.hide()
          }, 350)
        }
      })
    },
    copyInvoiceLine(data) {
      this.invoiceData.invoiceLines.push(JSON.parse(JSON.stringify(data)))

      this.$nextTick(() => {
        this.trHeightInvoiceLines = this.getHeight(this.$refs.rowInvoiceLines[0].offsetHeight)
        setTimeout(() => {
          this.$refs.formInvoiceLines.style.overflow = null
        }, 350)
      })
    },
    copyExpenses(data) {
      this.invoiceData.expenses.push(JSON.parse(JSON.stringify(data)))

      this.$nextTick(() => {
        this.trHeightExpenses = this.getHeight(this.$refs.rowExpenses[0].offsetHeight)
        setTimeout(() => {
          this.$refs.formExpenses.style.overflow = null
        }, 350)
      })
    },
    removeInvoiceLine(index) {
      this.invoiceData.invoiceLines.splice(index, 1)
      this.trHeightInvoiceLines = this.getHeight(this.$refs.rowInvoiceLines[0].offsetHeight)
    },
    removeExpenses(index) {
      this.invoiceData.expenses.splice(index, 1)
      this.trHeightExpenses = this.getHeight(this.$refs.rowExpenses[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trHeightInvoiceLines = this.getHeight(this.$refs.formInvoiceLines.scrollHeight)
        this.trHeightExpenses = this.getHeight(this.$refs.formExpenses.scrollHeight)
      })
    },
    getHeight(val) {
      if (val === null) {
        this.trHeight = 'auto'
      } else {
        this.trHeight = `${Number(val)}px`
      }
    },
    uploadImage() {
      this.showUploadLoader = true
      this.disableExpensesAddButton = true
      const file = document.querySelector('#refInputExpenses').files[0]
      if (file.size > 10485760) {
        this.showUploadLoader = false
        this.disableExpensesAddButton = false
        this.$swal({
          icon: 'error',
          title: 'Afbeeldingsgrootte',
          text: 'De maximale afbeeldinggrootte is 10mb. Het huidige bestand is te groot.',
          customClass: {
            confirmButton: 'btn btn-danger',
          },
        })
      } else {
        const userData = JSON.parse(localStorage.getItem('userData'))
        const reader = new FileReader()
        reader.addEventListener(
          'load',
          () => {
            const fileUploadData = {
              file: reader.result,
              relationModel: 'User',
              name: file.name,
              relationID: userData.id,
            }

            store.dispatch('app-invoice-create/uploadFile', fileUploadData)
              .then(response => {
                this.disableExpensesAddButton = false
                this.showUploadLoader = false
                this.addExpsensesData.fileName = file.name
                this.addExpsensesData.fileID = response.data.fileID

                this.$swal({
                  icon: 'success',
                  title: 'Upload',
                  text: 'Upload van de foto is gelukt!',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
              .catch(() => {
                this.disableExpensesAddButton = false
                this.showUploadLoader = false
                this.$swal({
                  icon: 'error',
                  title: 'Uploaden',
                  text: 'Er is een fout opgetreden bij het uploaden van het bestand. Probeer later het nog een keer.',
                  customClass: {
                    confirmButton: 'btn btn-danger',
                  },
                })
              })
          },
          false,
        )
        reader.readAsDataURL(file)
      }
    },
    setSelectedCompany(company) {
      this.$emit('set-selected-company', company)
    },
    setSelectedFreelancer(freelancer) {
      this.$emit('set-selected-freelancer', freelancer)
    },
    setSelectedBtw(id) {
      const btw = this.btwOptions.filter(b => b.value === id)[0]
      this.$emit('set-selected-btw', btw)
    },
    async onSubmit() {
      await this.$emit('set-invoice-data', this.invoiceData)
      await this.$emit('next-step')
    },
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice-create'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoicesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const blankInvoiceLine = {
      description: '',
      cost: null,
      quantity: null,
    }

    const blankExpenses = {
      description: '',
      amount: null,
      isInvoiced: false,
      fileID: null,
      reasonID: null,
    }

    const invoiceData = ref({
      invoiceLines: [JSON.parse(JSON.stringify(blankInvoiceLine))],
      expenses: [],
      description: null,
      reference: null,
      invoiceDate: formatDate(new Date()),
      btw: 0,
      organisation: null,
      freelancer: null,
    })

    const btwOptions = [
      {
        label: '21%',
        value: 0,
        amount: 21,
      },
      {
        label: '9%',
        value: 1,
        amount: 9,
      },
      {
        label: 'Vrijgesteld',
        value: 2,
        amount: 0,
      },
      {
        label: 'Verlegd',
        value: 3,
        amount: 0,
      },
    ]

    const expenseType = [
      {
        label: 'Reiskosten',
        value: 1,
      },
      {
        label: 'Bon',
        value: 2,
      },
    ]

    const expenseReasons = [
      {
        label: 'Lunchkosten',
        value: 1,
      },
      {
        label: 'Telefoonkosten',
        value: 2,
      },
      {
        label: 'Werkkleding',
        value: 3,
      },
      {
        label: 'Vakliteratuur',
        value: 4,
      },
      {
        label: 'Gereedschap',
        value: 5,
      },
      {
        label: 'Congressen en seminars',
        value: 6,
      },
      {
        label: 'Sportabonnement',
        value: 7,
      },
      {
        label: 'Overige',
        value: 99,
      },
    ]

    const {
      getValidationState,
    } = formValidation()

    return {
      invoiceData,
      blankInvoiceLine,
      blankExpenses,
      getValidationState,
      required,
      minAmount,
      integer,
      getFullName,
      formatDate,
      btwOptions,
      getPayPeriods,
      expenseType,
      expenseReasons,
    }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-autosuggest.scss';

.invoice-add-wrapper {
  .add-new-organisation-header {
    padding: $options-padding-y $options-padding-x;
    color: $success;

    &:hover {
      background-color: rgba($success, 0.12);
    }
  }
}
</style>
<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import '~@core/scss/base/components/variables-dark';

.form-item-section {
  background-color: $product-details-bg;
}

.form-item-action-col {
  width: 27px;
}

.repeater-form {
  // overflow: hidden;
  transition: .35s height;
}

.v-select {
  &.item-selector-title,
  &.payment-selector {
    background-color: #fff;

    .dark-layout & {
      background-color: unset;
    }
  }
}

.dark-layout {
  .form-item-section {
    background-color: $theme-dark-body-bg;

    .row .border {
      background-color: $theme-dark-card-bg;
    }

  }
}

.invoice-preview .invoice-number-date .title, .invoice-edit .invoice-number-date .title, .invoice-add .invoice-number-date .title {
  width: 200px !important;
}
</style>
