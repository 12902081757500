import axios from '@axios'
import axiosDefaultConfig from '@core/app-config/axiosDefaultConfig'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchInvoices(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}invoices`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addInvoice(ctx, invoice) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}invoices`, invoice)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    approveInvoice(ctx, invoice) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`${axiosDefaultConfig.backendEndPoint}invoices/${invoice.id}`, invoice)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    patchInvoice(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`${axiosDefaultConfig.backendEndPoint}invoices/${data.id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createInvoiceCollection(ctx, invoices) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}invoicecollections`, { invoiceIDs: invoices })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addCompany(ctx, company) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}companies`, company)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProfile(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}profile`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCompanies(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}companies`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCompany(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}companies/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadFile(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}file/upload`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    searchCocRegister(ctx, coc) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}kvk?q=${coc.q}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    previewCalculation(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}invoices/previewcalculation?totalAmount=${data.totalAmount}&totalExpenseAmount=${data.totalExpenseAmount}&companyPaysFee=${data.companyPaysFee}&companyID=${data.companyID}&freelancerID=${data.freelancerID}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchFreelancers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}freelancers`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchFreelancer(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}users/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addFreelancer(ctx, freelancer) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${axiosDefaultConfig.backendEndPoint}freelancer`, freelancer)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCompanyProfile(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}companyprofile`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchPayPeriods(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${axiosDefaultConfig.backendEndPoint}paychecks/periods`, { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
