<template>
  <form-wizard
    ref="refFormWizard"
    color="#29625b"
    :title="null"
    :subtitle="null"
    finish-button-text="Submit"
    back-button-text="Previous"
    hide-buttons
    class="checkout-form-wizard steps-transparent"
  >
    <!-- account detail tab -->
    <tab-content
      title="Factuur informatie"
      icon="feather icon-folder-plus"
    >
      <invoice-step-create-invoice-items
        v-if="profileData && companiesData || freelancersData"
        :user-data="userData"
        :profile-data="profileData"
        :companies-data="companiesData"
        :freelancers-data="freelancersData"
        :invoice-data="invoiceData"
        :selected-company-data="selectedCompany"
        :selected-freelancer-data="selectedFreelancer"
        :selected-btw-data="selectedBtw"
        :selected-pay-period-data="selectedPayPeriod"
        :total-amount-ex-btw-data="totalAmountExBtw"
        :total-btw-data="totalBtw"
        :total-amount-inc-btw-data="totalAmountIncBtw"
        :preview-calculation-data="previewCalculation"
        @set-selected-company="setSelectedCompany($event)"
        @set-selected-freelancer="setSelectedFreelancer($event)"
        @set-selected-btw="setSelectedBtw($event)"
        @set-total-amount-ex-btw="setTotalAmountExBtw($event)"
        @set-total-btw="setTotalBtw($event)"
        @set-total-amount-inc-btw="setTotalAmountIncBtw($event)"
        @set-preview-calculation="setPreviewCalculation($event)"
        @set-invoice-data="setInvoiceData($event)"
        @fetch-companies-or-feelancers="fetchCompaniesOrFreelancers"
        @next-step="formWizardNextStep"
      />
    </tab-content>
    <tab-content
      title="Facturatie & verloning"
      icon="feather icon-clipboard"
    >
      <invoice-step-settings
        :invoice-data="invoiceData"
        :selected-company-data="selectedCompany"
        :selected-btw-data="selectedBtw"
        :selected-pay-period-data="selectedPayPeriod"
        :total-amount-ex-btw-data="totalAmountExBtw"
        :total-btw-data="totalBtw"
        :total-amount-inc-btw-data="totalAmountIncBtw"
        :preview-calculation-data="previewCalculation"
        @set-selected-pay-periode="setPayPeriod($event)"
        @set-settings-data="setSettingsData($event)"
        @next-step="formWizardNextStep"
        @previous-step="formWizardPreviousStep"
      />
    </tab-content>
    <tab-content
      title="E-mail gegevens en bijlagen"
      icon="feather icon-mail"
    >
      <invoice-step-email-settings
        :selected-company-data="selectedCompany"
        :selected-btw-data="selectedBtw"
        :selected-pay-period-data="selectedPayPeriod"
        :total-amount-ex-btw-data="totalAmountExBtw"
        :total-btw-data="totalBtw"
        :total-amount-inc-btw-data="totalAmountIncBtw"
        :preview-calculation-data="previewCalculation"
        @set-invoice-email-data="setInvoiceEmailData($event)"
        @next-step="formWizardNextStep"
        @previous-step="formWizardPreviousStep"
      />
    </tab-content>
  </form-wizard>
</template>

<script>
import { FormWizard, TabContent } from 'vue-form-wizard'
import { onUnmounted, ref } from '@vue/composition-api'
import store from '@/store'
import invoicesStoreModule from '@/views/pages/invoices/invoicesStoreModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import InvoiceStepCreateInvoiceItems from '@/views/pages/invoices/add/InvoiceStepCreateInvoiceItems.vue'
import InvoiceStepSettings from '@/views/pages/invoices/add/InvoiceStepSettings.vue'
import InvoiceStepEmailSettings from '@/views/pages/invoices/add/InvoiceStepEmailSettings.vue'
import { getPayPeriods } from '@core/utils/utils'

export default {
  components: {
    InvoiceStepSettings,
    InvoiceStepEmailSettings,
    InvoiceStepCreateInvoiceItems,
    FormWizard,
    TabContent,
  },
  data() {
    return {
      userData: {},
      profileData: null,
      companiesData: null,
      selectedCompany: null,
      freelancersData: null,
      selectedFreelancer: null,
      selectedBtw: {
        label: '21%',
        value: 0,
        amount: 21,
      },
      selectedPayPeriod: null,
      totalAmountExBtw: 0,
      totalBtw: 0,
      totalAmountIncBtw: 0,
      grossSalary: 0,
      previewCalculation: {
        grossSalary: 0,
        netPay: 0,
        realGrossSalary: 0,
      },
      invoiceData: {
        freelancerID: null,
        companyID: null,
        isSelfBilling: null,
        paycheckPeriod: null,
        paycheckHours: null,
        paycheckDays: null,
        invoiceDate: null,
        expiryDays: null,
        vatRate: null,
        vatReason: null,
        description: null,
        reference: null,
        lines: null,
        expenses: null,
        email: {
          language: 'nl',
          cc: null,
          files: null,
          text: null,
        },
      },
    }
  },
  beforeMount() {
    this.userData = JSON.parse(localStorage.getItem('userData'))
  },
  mounted() {
    this.fetchProfile()
    this.fetchCompaniesOrFreelancers()
    this.fetchCompany()
  },
  methods: {
    fetchProfile() {
      store
        .dispatch('app-invoices-add/fetchProfile')
        .then(response => {
          this.profileData = response.data
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Ophalen profiel',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer het nogmaals.',
            },
          })
        })
    },
    fetchCompaniesOrFreelancers() {
      if (this.userData.type === 1) {
        store
          .dispatch('app-invoices-add/fetchCompanies', {
            sortBy: 'name',
          })
          .then(response => {
            this.companiesData = response.data.items
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ophalen Bedrijven',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer het nogmaals.',
              },
            })
          })
      }

      if (this.userData.type === 2) {
        store
          .dispatch('app-invoices-add/fetchFreelancers', {})
          .then(response => {
            this.freelancersData = response.data.items
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ophalen Freelancers',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer het nogmaals.',
              },
            })
          })
      }
    },
    fetchCompany() {
      if (this.userData.type === 2) {
        store
          .dispatch('app-invoices-add/fetchCompanyProfile')
          .then(response => {
            this.selectedCompany = response.data
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ophalen Bedrijfsinformatie',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
                text: 'Er is een fout opgetreden bij het ophalen van de data. Probeer het nogmaals.',
              },
            })
          })
      }
    },
    setSelectedCompany(data) {
      this.selectedCompany = data
    },
    setSelectedFreelancer(data) {
      this.selectedFreelancer = data
    },
    setSelectedBtw(data) {
      this.selectedBtw = data
    },
    setPayPeriod(data) {
      this.selectedPayPeriod = data
    },
    setTotalAmountExBtw(data) {
      // eslint-disable-next-line radix
      this.totalAmountExBtw = parseFloat(data)
    },
    setTotalBtw(data) {
      // eslint-disable-next-line radix
      this.totalBtw = parseFloat(data)
    },
    setTotalAmountIncBtw(data) {
      // eslint-disable-next-line radix
      this.totalAmountIncBtw = parseFloat(data)
    },
    setPreviewCalculation(data) {
      // eslint-disable-next-line radix
      this.previewCalculation = data
    },
    setInvoiceData(data) {
      if (this.userData.type === 1) {
        this.invoiceData.freelancerID = JSON.parse(localStorage.getItem('userData')).id
        this.invoiceData.companyID = this.selectedCompany.id
        this.invoiceData.isSelfBilling = 0
      }

      if (this.userData.type === 2) {
        this.invoiceData.freelancerID = this.selectedFreelancer.id
        this.invoiceData.companyID = JSON.parse(localStorage.getItem('userData')).id
        this.invoiceData.isSelfBilling = 1
      }

      this.invoiceData.invoiceDate = data.invoiceDate
      this.invoiceData.vatRate = this.selectedBtw.amount
      this.invoiceData.vatReason = data.vatReason ? data.vatReason : ''
      this.invoiceData.lines = data.invoiceLines.length > 0 ? data.invoiceLines : []
      this.invoiceData.expenses = data.expenses.length > 0 ? data.expenses : []
    },
    setSettingsData(data) {
      const period = this.getPayPeriods().filter(b => b.value === data.paycheckPeriod)[0]
      this.invoiceData.paycheckPeriod = period.value
      this.invoiceData.paycheckHours = data.paycheckHours
      this.invoiceData.paycheckDays = data.paycheckDays
      this.invoiceData.expiryDays = data.expiryDays
      this.invoiceData.description = data.description ? data.description : null
      this.invoiceData.reference = data.reference ? data.description : null
    },
    setInvoiceEmailData(data) {
      this.invoiceData.email.cc = [data.cc]
      this.invoiceData.email.files = data.fileID ? [data.fileID] : null
      this.invoiceData.email.text = data.message

      store
        .dispatch('app-invoices-add/addInvoice', this.invoiceData)
        .then(() => {
          this.$router.push({ name: 'app-invoices' })
        })
        .catch(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Factuur aanmaken',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: 'Er is een fout opgetreden bij het opslaan van de data. Probeer later het nog een keer.',
            },
          })
        })
    },
  },
  setup() {
    const INVOCIES_ADD_APP_STORE_MODULE_NAME = 'app-invoices-add'

    // Register module
    if (!store.hasModule(INVOCIES_ADD_APP_STORE_MODULE_NAME)) store.registerModule(INVOCIES_ADD_APP_STORE_MODULE_NAME, invoicesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOCIES_ADD_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOCIES_ADD_APP_STORE_MODULE_NAME)
    })

    const refFormWizard = ref(null)
    const formWizardNextStep = () => {
      refFormWizard.value.nextTab()
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }

    const formWizardPreviousStep = () => {
      refFormWizard.value.prevTab()
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      })
    }

    // ? This is just dummy details
    const checkoutDetails = ref({
      address: {
        fullName: '',
        mobile: '',
        houseNo: '',
        landmark: '',
        city: '',
        pincode: '',
        state: '',
        addressType: null,
      },
      payment: {
        cvv: '',
      },
    })

    return {
      refFormWizard,
      formWizardNextStep,
      formWizardPreviousStep,
      // Dummy Details
      checkoutDetails,
      getPayPeriods,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '~@core/scss/base/pages/app-ecommerce.scss';
@import '~vue-form-wizard/dist/vue-form-wizard.min.css';
</style>

<style lang="scss">
[dir] .vue-form-wizard .wizard-navigation .wizard-nav li.active a .checked {
  box-shadow: 0 3px 6px 0 rgb(41,98,91,0.4) !important;
}

.checkout-form-wizard ::v-deep {
  .wizard-tab-content {
    box-shadow: none !important;
    background: transparent !important;
    padding: 0;
  }
}
</style>
